exports.onRouteUpdate = (location) => {

  window.onload = () => { console.log('route updated / window onload') }

  if (location.location.hash) {
  	var hash = location.location.hash;
  	hash = hash.replace('#','')
    console.log('hash is: ' + hash);

    if (hash.indexOf(':') !== -1) {
      // get deep link...
      let deeplink = hash.split(':');
      hash = deeplink[0];
      let tab = deeplink[1];
      console.log('hash is:' + hash + ' tab is:' + tab);

      if (tab == 'safety-security') {
        var tab_ele = document.getElementById('react-tabs-14');
        if (tab_ele) {
          tab_ele.click();
          console.log(tab_ele);          
        }
      }
    }

  	var element = document.getElementById(hash);
	  if (!element) return false
	  setTimeout(function() {
  		element.scrollIntoView({ behavior: 'smooth' })
		}, 0);
	  return true
	}
}

// gatsby-browser.js

exports.onClientEntry = () => {
  // NOTE: Don't polyfill Promise here (Gatsby already includes a Promise polyfill)

  // IntersectionObserver polyfill for gatsby-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    require(`intersection-observer`)
    console.log(`👍 IntersectionObserver is polyfilled`)
  }

  // Object-fit/Object-position polyfill for gatsby-image (IE)
  const testImg = document.createElement(`img`)
  if (
    typeof testImg.style.objectFit === `undefined` ||
    typeof testImg.style.objectPosition === `undefined`
  ) {
    require(`object-fit-images`)()
    console.log(`👍 Object-fit/Object-position are polyfilled`)
  }
}