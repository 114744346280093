module.exports = [{
      plugin: require('/Users/cedricschaedel/Sites/telegraph-tower-gb/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/cedricschaedel/Sites/telegraph-tower-gb/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-149686576-1","head":false},
    },{
      plugin: require('/Users/cedricschaedel/Sites/telegraph-tower-gb/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
